select.form-control {
  width: auto;
}

.card-scroll {
  ::-webkit-scrollbar {
    width: 7px;
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    // TODO: Check on Windows machines.
    // This style is only being done for webkit since other vendors
    // likely always display the scroll bar.
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.container-scroll {
  overflow-y: scroll;
  height: 250px;
}

.list-scroll {
  overflow-y: scroll;
  max-height: 185px;
}
