.strata-logo img {
  width: 6rem;
}

.footer-copyright img {
  width: 71px;
  &:hover {
    opacity: 0.75;
  }
}
