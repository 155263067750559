.navbar-brand-image {
  width: 71px;
  &:hover {
    opacity: 0.75;
  }
}

.navbar-border {
  border-bottom: 1px solid;
}

.navbar-box-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.navbar-sub {
  background: $color-white;
  width: 100%;

  ul {
    height: 100%;
  }

  a:hover {
    text-decoration: underline;
  }
}

.nav-link {
  /* same padding as btn-sm */
  padding: 0.25rem 0.5rem;
}

.breadcrumbs {
  color: darken($color-gray-light, 30%);
  font-size: $font-size-small;
}
