#filter_container {
  p {
    margin-right: 2em;
  }

  label {
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: 300;
  }
}

.filter-button {
  max-height: 31px;
  align-self: center;
  margin-top: 13px;
  min-width: 5em;
}
