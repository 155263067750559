.table td,
th {
  vertical-align: middle;
}

th {
  font-weight: $font-weight-light;
  text-transform: uppercase;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

td {
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
