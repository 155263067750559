.card-main {
  .card-header {
    background: $color-tertiary;
  }

  .table {
    font-size: $font-size-small;

    thead th {
      border-top: none;
      padding-top: 0px;
    }
  }
}
