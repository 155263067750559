// ********************
// Colors
// ********************

$color-primary: #456d9e;
$color-secondary: #53bde3;
$color-tertiary: #d0dceb;
$color-white: #fff;
$color-gray: #c7cbcf;
$color-gray-light: #eaeaea;
$color-green: #88b540;

// Strata Colors
$color__strata-action-01: #007c9f;
$color__strata-action-02: #005f7a;

// ********************
// Colors for specific elements
// ********************

$color-bg: #f8fafc;
$color-link-text: #2581c6;

$color-pure-white: #ffffff;

// Strata primary button colors
$btn__color__strata__default-background: $color__strata-action-01;
$btn__color__strata__hover-background: $color__strata-action-02;
$btn__color__strata__focus-background: $color__strata-action-01;

$btn__color__primary: $color-pure-white !default;
