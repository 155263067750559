// Rotate caret.
.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}

.dropdown-toggle:after {
  transition: 0.5s;
}

.btn-primary-strata {
  color: $btn__color__primary;
  background: $btn__color__strata__default-background;

  &:focus {
    outline: 4px solid $btn__color__strata__default-background;
    outline-offset: 1px;
    background: $btn__color__strata__focus-background;
    border-radius: 6px;
  }

  &:hover {
    color: $btn__color__primary !important;
    background: $btn__color__strata__hover-background !important;
  }
}
